var ContentView_1;
import { __decorate } from "tslib";
import { EventbusType, LocalStorage, WindowService } from '@movecloser/front-core';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Addon, ContentType, ImageRatio, RelatedType } from '@d24/modules/front';
import { Inject } from '@plugin/inversify';
import { isDevelopmentEnv, isProductionEnv } from '@support/environment';
import { SiteLogoVariant, SiteServiceType } from '@service/site';
import { Article } from '../components/Article';
import { ContentRepositoryType } from '../contracts';
import { isCallingForFile, trimSlugQueryParams } from '../helpers/content';
import { Landing } from '../components/Landing';
import { NoAccessPath, SubscriptionRequiredPath } from '../routes';
import { Page } from '../components/Page';
import { SupportedFormatsMiddlewareType } from '../services';
import { detectSupportedFormats } from '../helpers';
import { RelatedServiceType } from '@service/related';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let ContentView = ContentView_1 = class ContentView extends Vue {
    constructor() {
        super(...arguments);
        // Vuex
        // public get result (): RegistryResponse | null {
        //   return this.$store.getters['content/result']
        // }
        this.result = null;
    }
    removeTrailingSlash(str) {
        if (str.endsWith('/')) {
            return str.slice(0, -1);
        }
        return str;
    }
    /**
     * The Vue component to render.
     */
    get component() {
        switch (this.type) {
            case ContentType.Article:
            case ContentType.LiveArticle: {
                return Article;
            }
            case ContentType.Landing: {
                return Landing;
            }
            case ContentType.Page:
            case ContentType.SmartPage:
            default: {
                return Page;
            }
        }
    }
    get author() {
        var _a, _b, _c;
        if (((_a = this.result) === null || _a === void 0 ? void 0 : _a.related) && ((_b = this.result) === null || _b === void 0 ? void 0 : _b.related[RelatedType.Author])) {
            const authors = (_c = this.result) === null || _c === void 0 ? void 0 : _c.related[RelatedType.Author];
            if (authors && authors[this.result.content.author.value]) {
                const author = authors[this.result.content.author.value] || null;
                if (!author)
                    return null;
                return author;
            }
        }
        return null;
    }
    get authorName() {
        const author = this.author;
        if (!author)
            return '';
        return (author.firstName + ' ' + author.lastName).trim();
    }
    tagName(id) {
        var _a, _b, _c;
        if (((_a = this.result) === null || _a === void 0 ? void 0 : _a.related) && ((_b = this.result) === null || _b === void 0 ? void 0 : _b.related[RelatedType.Tag])) {
            const tags = (_c = this.result) === null || _c === void 0 ? void 0 : _c.related[RelatedType.Tag];
            if (tags && tags[id]) {
                return tags[id].name;
            }
        }
        return '';
    }
    labelName(id) {
        var _a, _b, _c;
        if (((_a = this.result) === null || _a === void 0 ? void 0 : _a.related) && ((_b = this.result) === null || _b === void 0 ? void 0 : _b.related[RelatedType.Label])) {
            const tags = (_c = this.result) === null || _c === void 0 ? void 0 : _c.related[RelatedType.Label];
            if (tags && tags[id]) {
                return tags[id].name;
            }
        }
        return '';
    }
    teaser(lead, length) {
        const words = lead.split(' ');
        let output = '';
        for (let i = 0; i < words.length; i++) {
            if ((output + ' ' + words[i]).length > length) {
                break;
            }
            output += ' ' + words[i];
        }
        return output.trim();
    }
    removeMarkdown(text) {
        return text ? text.replace(/[*]/g, '') : '';
    }
    htmlEntityDecode(str) {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = str;
        return textArea.value;
    }
    get pageTypeForAnalytics() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7;
        switch (this.type) {
            case ContentType.Article:
            case ContentType.LiveArticle: {
                const authorAddon = ((_a = this.result) === null || _a === void 0 ? void 0 : _a.content.properties[Addon.Author]) || [];
                const tagsAddon = ((_b = this.result) === null || _b === void 0 ? void 0 : _b.content.properties[Addon.Tags]) || [];
                const labelsAddon = ((_c = this.result) === null || _c === void 0 ? void 0 : _c.content.properties[Addon.Labels]) || [];
                return {
                    type: 'article_page',
                    contentGroup: ((_e = (_d = this.result) === null || _d === void 0 ? void 0 : _d.content.parent) === null || _e === void 0 ? void 0 : _e.name) || '',
                    category: ((_g = (_f = this.result) === null || _f === void 0 ? void 0 : _f.content.parent) === null || _g === void 0 ? void 0 : _g.name) || '',
                    id: ((_j = (_h = this.result) === null || _h === void 0 ? void 0 : _h.content.parent) === null || _j === void 0 ? void 0 : _j.id) || '',
                    // @ts-expect-error
                    tag: tagsAddon.map((tag) => this.tagName(tag.value)).join('|'),
                    // @ts-expect-error
                    tags: tagsAddon.map((tag) => this.tagName(tag.value)),
                    // @ts-expect-error
                    labels: labelsAddon.map((label) => this.labelName(label.value)),
                    // @ts-expect-error
                    source: (authorAddon.source && (authorAddon === null || authorAddon === void 0 ? void 0 : authorAddon.source) === 'PAP') ? 'pap' : 'editorial'
                };
            }
            case ContentType.Landing: {
                return {
                    type: 'article_page',
                    contentGroup: ((_l = (_k = this.result) === null || _k === void 0 ? void 0 : _k.content.parent) === null || _l === void 0 ? void 0 : _l.name) || '',
                    category: ((_o = (_m = this.result) === null || _m === void 0 ? void 0 : _m.content.parent) === null || _o === void 0 ? void 0 : _o.name) || '',
                    id: ((_q = (_p = this.result) === null || _p === void 0 ? void 0 : _p.content.parent) === null || _q === void 0 ? void 0 : _q.id) || ''
                };
            }
            case ContentType.Page: {
                if (((_r = this.result) === null || _r === void 0 ? void 0 : _r.content.urlPath) === 'index' || ((_s = this.result) === null || _s === void 0 ? void 0 : _s.content.urlPath) === '/') {
                    return {
                        type: 'main_page',
                        contentGroup: ((_t = this.result) === null || _t === void 0 ? void 0 : _t.content.title) || '',
                        category: ((_v = (_u = this.result) === null || _u === void 0 ? void 0 : _u.content.parent) === null || _v === void 0 ? void 0 : _v.name) || '',
                        id: ((_x = (_w = this.result) === null || _w === void 0 ? void 0 : _w.content.parent) === null || _x === void 0 ? void 0 : _x.id) || ''
                    };
                }
                return {
                    type: 'category_page',
                    contentGroup: ((_y = this.result) === null || _y === void 0 ? void 0 : _y.content.title) || '',
                    category: ((_z = this.result) === null || _z === void 0 ? void 0 : _z.content.title) || '',
                    id: ((_0 = this.result) === null || _0 === void 0 ? void 0 : _0.content.id) || ''
                };
            }
            case ContentType.SmartPage: {
                return {
                    type: 'tag_page',
                    contentGroup: ((_1 = this.result) === null || _1 === void 0 ? void 0 : _1.content.title) || '',
                    category: ((_2 = this.result) === null || _2 === void 0 ? void 0 : _2.content.title) || '',
                    id: ((_4 = (_3 = this.result) === null || _3 === void 0 ? void 0 : _3.content.parent) === null || _4 === void 0 ? void 0 : _4.id) || ''
                };
            }
            default: {
                return {
                    type: 'other_page',
                    contentGroup: ((_5 = this.result) === null || _5 === void 0 ? void 0 : _5.content.title) || '',
                    category: ((_6 = this.result) === null || _6 === void 0 ? void 0 : _6.content.title) || '',
                    id: ((_7 = this.result) === null || _7 === void 0 ? void 0 : _7.content.id) || ''
                };
            }
        }
    }
    /**
     * Determines whether the current page represents the article.
     */
    get isArticle() {
        return this.type === ContentType.Article || this.type === ContentType.LiveArticle;
    }
    /**
     * Determine if Auth is required to display anything.
     */
    get isAuthRequired() {
        return this.$store.getters.isAuthRequired;
    }
    /**
     * Determines whether the current page represents the standard page.
     */
    get isPage() {
        return this.type === ContentType.Page || this.type === ContentType.SmartPage;
    }
    /**
     * Determines whether app is waiting for auth
     */
    get isWaitingForAuth() {
        return this.$store.getters['profile/isWaitingForAuth'];
    }
    /**
     * Determines whether the User is currently logged-in.
     */
    get isUserLoggedIn() {
        return this.$store.getters['profile/isUserLoggedIn'];
    }
    /**
     * get the current user
     */
    get me() {
        return this.$store.getters['profile/me'];
    }
    /**
     * Content type.
     */
    get type() {
        if (this.result === null || !this.result.content) {
            return;
        }
        return this.result.content.type;
    }
    async handleAnalytics() {
        var _a, _b, _c, _d, _e;
        if (!this.result) {
            return;
        }
        if (typeof window === 'undefined' || typeof document === 'undefined') {
            return;
        }
        const userId = LocalStorage.get('d24-user-id');
        const clientId = LocalStorage.get('d24-client-id');
        const userComId = LocalStorage.get('d24-user-com-id');
        const subType = LocalStorage.get('d24-client-sub');
        this.relatedServices.storeRelated(this.result.related);
        let analyticsPayload = {
            locale: this.siteService.getActiveSite().locale,
            // eslint-disable-next-line @typescript-eslint/camelcase
            logged_status: this.isUserLoggedIn,
            // eslint-disable-next-line @typescript-eslint/camelcase
            user_id: userId || null,
            // eslint-disable-next-line @typescript-eslint/camelcase
            client_id: clientId || null,
            // eslint-disable-next-line @typescript-eslint/camelcase
            user_com_id: userComId || null,
            // eslint-disable-next-line @typescript-eslint/camelcase
            subscription_option: subType || null,
            path: this.$route.fullPath,
            title: this.result.content.title,
            article: null,
            page: {
                type: this.pageTypeForAnalytics.type,
                // eslint-disable-next-line @typescript-eslint/camelcase
                content_group: this.pageTypeForAnalytics.contentGroup
            }
        };
        if (this.type === ContentType.Page) {
            analyticsPayload = Object.assign(analyticsPayload, {
                article: {
                    category: this.pageTypeForAnalytics.category,
                    categoryId: this.pageTypeForAnalytics.id
                }
            });
            this.eventBus.emit('app:category.view', analyticsPayload);
        }
        if (this.type === ContentType.Article || this.type === ContentType.LiveArticle) {
            const lead = this.htmlEntityDecode(this.removeMarkdown(this.result.content.properties.lead));
            const imageUrl = await this.resolveCoverImage();
            let isVideo = false;
            if (typeof this.result.content.properties.cover === 'object' &&
                this.result.content.properties.cover.video) {
                isVideo = this.result.content.properties.cover.video.url !== '';
            }
            let fullUrl = null;
            if (isProductionEnv()) {
                fullUrl = this.siteService.getActiveSite().address + this.result.content.urlPath;
            }
            analyticsPayload = Object.assign(analyticsPayload, {
                article: {
                    id: this.result.content.contentId,
                    domain: this.siteService.getActiveSite().name,
                    url: fullUrl,
                    description: lead,
                    teaser: this.teaser(lead, 100),
                    isVideo,
                    imageUrl,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    is_editorial: (_b = (_a = this.author) === null || _a === void 0 ? void 0 : _a.isEditorial) !== null && _b !== void 0 ? _b : false,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    is_sponsored: this.result.content.contentProperties.sponsored === true,
                    source: this.pageTypeForAnalytics.source,
                    author: (_c = this.authorName) !== null && _c !== void 0 ? _c : '',
                    authorId: (_d = this.result.content.author.value) !== null && _d !== void 0 ? _d : '',
                    tag: this.pageTypeForAnalytics.tag,
                    tags: this.pageTypeForAnalytics.tags,
                    labels: this.pageTypeForAnalytics.labels,
                    category: this.pageTypeForAnalytics.category,
                    categoryId: this.pageTypeForAnalytics.id,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    publishedAt: this.result.content.publicationDate,
                    // modifiedAt: '',
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    comments_num: (_e = this.result.content.commentsCount) !== null && _e !== void 0 ? _e : 0
                }
            });
            this.eventBus.emit('app:article.view', analyticsPayload);
            this.eventBus.emit('app:author.view', analyticsPayload);
            this.eventBus.emit('app:category.view', analyticsPayload);
        }
        this.eventBus.emit('app:page.loaded', analyticsPayload);
    }
    async reloadContent(query = {}) {
        var _a;
        let url = this.$route.path;
        if (url[0] === '/') {
            url = url.substring(1);
        }
        try {
            this.result = await this.contentRepository.load(url, query, false) || null;
            this.$store.commit('toggleAuthRequirement', false);
        }
        catch (error) {
            ContentView_1.handleInvariants(error, this.siteService.getActiveSite(), (message) => {
                throw new Error(message);
            }, this.$store, (_a = [...this.$store.getters.routeHistory].pop()) !== null && _a !== void 0 ? _a : this.$route.fullPath, (target) => {
                this.$router.push(target);
            });
        }
    }
    setAdTargeting(targeting) {
        this.$emit('setAdTargeting', targeting);
    }
    static handleInvariants(error, site, handler, store, route, redirect) {
        var _a;
        switch (error.status) {
            case 401:
            case 403:
                if (!WindowService.isDefined) {
                    store.commit('toggleAuthRequirement', true);
                }
                else {
                    setTimeout(() => {
                        try {
                            redirect(ContentView_1.resolveRedirect(site, 'noAccessRedirect', route));
                        }
                        catch (e) {
                            // Note! Do nothing. It's to prevent logging errors caused by 401.
                            // console.debug(e.message)
                        }
                    }, 0);
                }
                break;
            case 402:
                if (!WindowService.isDefined) {
                    store.commit('toggleAuthRequirement', true);
                }
                else {
                    setTimeout(() => {
                        try {
                            redirect(ContentView_1.resolveRedirect(site, 'noAccessRedirect', route, SubscriptionRequiredPath));
                        }
                        catch (e) {
                            // Note! Do nothing. It's to prevent logging errors caused by 402.
                            // console.debug(e.message)
                        }
                    }, 0);
                }
                break;
            case 410:
                redirect(error.payload.redirectTo, (_a = error.payload.redirectionCode) !== null && _a !== void 0 ? _a : 303);
                break;
            case 503:
                store.commit('toggleMaintenanceMode', true);
                break;
            default:
                // eslint-disable-next-line no-case-declarations
                let message = `[ContentView]: ${error.message}`;
                if (typeof error.payload === 'object' && error.payload !== null && typeof error.payload.slug !== 'undefined') {
                    message += ` for ${error.payload.slug}`;
                }
                handler(message, Number(error.status));
        }
    }
    async resolveCoverImage() {
        if (!this.result) {
            return null;
        }
        let src = null;
        if (typeof this.result.content.properties.cover === 'object' &&
            this.result.content.properties.cover.image.value) {
            try {
                const image = await this.relatedServices.resolve(this.result.content.properties.cover.image);
                const variants = Object.values(image.srcset);
                let variant = variants.find(item => item.ratio === ImageRatio.Standard169 && item.width === 640);
                if (!variant) {
                    variant = variants.find(item => item.ratio === ImageRatio.Original && item.width === 580);
                }
                src = (variant === null || variant === void 0 ? void 0 : variant.orgUrl) || (variant === null || variant === void 0 ? void 0 : variant.url.replace(/(\.webp|\.jpg|\.png)/, `.${image.orgMime}`)) || null;
                const cdnUrl = 'https://cdn.defence24.pl';
                if (src && !src.startsWith('http')) {
                    src = `${cdnUrl}${src}`;
                }
            }
            catch (e) {
                console.warn(e);
            }
        }
        return src;
    }
    static resolveRedirect(site, destinationKey, current, fallback = NoAccessPath) {
        const path = site.getProperty(destinationKey, fallback);
        if (typeof path !== 'string' || path === current) {
            // When path is not specified, it's value is invalid or we've received an error at that path,
            // we need to set an internal fallback address. This action is required to prevent redirection loop.
            return fallback;
        }
        return path;
    }
    onIsUserLoggedIn(isUserLoggedIn) {
        if (isUserLoggedIn) {
            this.reloadContent();
        }
    }
};
ContentView.invariantRedirects = 0;
__decorate([
    Inject(ContentRepositoryType)
], ContentView.prototype, "contentRepository", void 0);
__decorate([
    Inject(RelatedServiceType)
], ContentView.prototype, "relatedServices", void 0);
__decorate([
    Inject(EventbusType)
], ContentView.prototype, "eventBus", void 0);
__decorate([
    Inject(SiteServiceType)
], ContentView.prototype, "siteService", void 0);
__decorate([
    Watch('isUserLoggedIn')
], ContentView.prototype, "onIsUserLoggedIn", null);
ContentView = ContentView_1 = __decorate([
    Component({
        name: 'ContentView',
        components: {
            Article,
            Page
        },
        metaInfo() {
            var _a, _b, _c, _d;
            const site = this.siteService.getActiveSite();
            const domain = site.address.slice(0, -1);
            const logoSrc = site.logo[SiteLogoVariant.Light];
            // let params = ''
            // if (this.$route.query.page) {
            //   params = '?' + new URLSearchParams({ page: this.$route.query.page as string }).toString()
            // }
            const links = [];
            if (this.result && this.result.content) {
                const path = this.removeTrailingSlash(this.$route.path);
                this.result.meta.push({
                    property: 'og:url',
                    content: domain + path
                });
                links.push({
                    rel: 'canonical',
                    href: (!!this.result.canonical && this.result.canonical.length > 0) ? this.result.canonical : (domain + path)
                });
                if ((_a = this.result.pagination) === null || _a === void 0 ? void 0 : _a.next) {
                    links.push({
                        rel: 'next',
                        href: (_b = this.result.pagination) === null || _b === void 0 ? void 0 : _b.next
                    });
                }
                if ((_c = this.result.pagination) === null || _c === void 0 ? void 0 : _c.prev) {
                    links.push({
                        rel: 'prev',
                        href: (_d = this.result.pagination) === null || _d === void 0 ? void 0 : _d.prev
                    });
                }
                if (logoSrc) {
                    this.result.meta.push({
                        property: 'og:logo',
                        content: logoSrc
                    });
                    if (this.type && ![ContentType.Article, ContentType.LiveArticle].includes(this.type)) {
                        this.result.meta.push({
                            property: 'og:image',
                            content: logoSrc
                        });
                    }
                }
            }
            return this.result && this.result.content
                ? {
                    title: this.result.content.title,
                    meta: this.result.meta,
                    link: links
                } : {};
        },
        async asyncData(context) {
            var _a;
            let { url } = context;
            url = trimSlugQueryParams(url);
            if (context.app && context.app.$store && context.app.$store.getters.routeHistory.length) {
                const previousRoute = context.app.$store.getters.routeHistory[0];
                if (previousRoute) {
                    const urlHash = url.substring(url.indexOf('#'));
                    if (url === `${previousRoute.fullPath}${urlHash}`) {
                        return;
                    }
                }
            }
            // This line is responsible for preventing to load `hot-update.XXX.json` or other static files.
            if (isCallingForFile(url) && isDevelopmentEnv()) {
                return;
            }
            const siteService = context.app.$container.get(SiteServiceType);
            const repository = context.app.$container.get(ContentRepositoryType);
            const supportedFormats = context.app.$container.get(SupportedFormatsMiddlewareType);
            if (!context.app.$store.getters['content/hasSupportedFormats']) {
                const supportedFormats = detectSupportedFormats(context);
                context.app.$store.commit('content/addSupportedFormat', supportedFormats);
            }
            if (!supportedFormats.hasSupportedFormats()) {
                supportedFormats.setSupportedFormats(context.app.$store.getters['content/supportedFormats']);
            }
            try {
                return { result: context.app.$store.getters.isMaintenance ? null : await repository.load(url, {}, false) };
            }
            catch (error) {
                // Note! This is on purpose. Decorator is execute before actual code so there's ContentView already defined.
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                ContentView_1.handleInvariants(error, siteService.getActiveSite(), context.error, context.app.$store, (_a = [...context.app.$store.getters.routeHistory].pop()) !== null && _a !== void 0 ? _a : context.app.$route.fullPath, context.isServer ? context.redirect : (target) => {
                    context.app.$router.push(target);
                });
                return { result: null };
            }
        },
        mounted() {
            var _a, _b;
            if (this.isAuthRequired && !this.isWaitingForAuth && !this.isUserLoggedIn && !this.result) {
                this.$router.push(
                // Note! This is on purpose. Decorator is execute before actual code so there's ContentView already defined.
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                ContentView_1.resolveRedirect(this.siteService.getActiveSite(), 'noAccessRedirect', this.$route.fullPath));
            }
            this.handleAnalytics();
            if (this.result) {
                if (this.$route.path === '/') {
                    const title = (_b = (_a = this.result) === null || _a === void 0 ? void 0 : _a.meta.find((singleMeta) => singleMeta.name === 'title')) === null || _b === void 0 ? void 0 : _b.content;
                    this.$store.commit('SET_SITE_META_TITLE', title || null);
                }
            }
        },
        beforeRouteUpdate(to, from, next) {
            this.$store.dispatch('pushRouteToHistory', from);
            this.$nextTick(() => {
                next();
            });
        }
    })
], ContentView);
export { ContentView };
export default ContentView;
